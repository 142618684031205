@import '_normalize.scss';
@import '_mixins.scss';
@import '_variables.scss';

body {
	margin: 0;
	font-family:
		'Instrument Sans',
		sans-serif,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--color-font-primary);
	background-color: #edf2f3;
}
