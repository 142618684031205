:root {
	--color-bg-primary: #071731;
	--color-bg-secondary: #f7fafb;
	--color-bg-white: #fff;

	--color-accent: #4594ff;

	--color-font-primary: #11396f;
	--color-font-primary-blured-40: #11396f66;
	--color-font-primary-blured-60: #11396f99;
	--color-font-secondary: #f7fafb;
	--color-font-white: #fff;
}
